





















































import { Component, Vue } from 'vue-property-decorator';
import {
  CollaborateurRelanceModal,
  CollaborateursTable,
  CollaborateurAjout,
  CollaborateurRadiation
} from '@/components';
import { collaborateurs, contextEmployeur, demo, toast } from '@/store';
import { Collaborateur } from '@/models';
import { CollaborateurService } from '@/services';
import { ERRORTYPE } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: { CollaborateursTable, CollaborateurAjout, CollaborateurRadiation, CollaborateurRelanceModal }
})
export default class Collaborateurs extends Vue {
  private refs: any = this.$refs;
  private collabRelaunchModalState = false;
  private panelRadiationState = false;
  private selectedCollaborateur: Collaborateur | null = null;

  async mounted() {
    await this.initCollaborateurs();
  }

  private async initCollaborateurs() {
    if (this.getNpm) {
      await collaborateurs.initCollaborateurs({ npm: this.getNpm, demo: this.getDemo });
    }
  }

  private openPanelAjoutCollaborateur() {
    const ref = this.refs.addCollaborateur;
    ref.newCollab();
  }

  private closePanelRadiation() {
    this.panelRadiationState = false;
  }

  private openPanelRadierCollaborateur(npp: string) {
    const collab = this.getCollaborateurByNumero(npp);
    if (!collab?.npp) return;
    this.panelRadiationState = true;
    this.selectedCollaborateur = collab;
  }

  private async relanceCollaborateur(npp: string) {
    if (!this.getContractInfo?.numeroContrat && !this.getContractInfo?.versionContrat) {
      return;
    }

    if (
      (await CollaborateurService.relanceCollaborateur(
        npp,
        this.getContractInfo?.numeroContrat,
        this.getContractInfo?.versionContrat
      )) &&
      this.getCollaborateurs
    ) {
      const collab = this.getCollaborateurs.filter(c => c.npp === npp)[0];
      this.selectedCollaborateur = collab;
      this.collabRelaunchModalState = true;
    }
  }

  private async addCollaborateur(payload: Collaborateur) {
    const collab = [payload];
    const numeroContrat = this.getContractInfo?.numeroContrat;
    const versionContrat = this.getContractInfo?.versionContrat;
    if (this.getNpm && numeroContrat && versionContrat != null) {
      if (await CollaborateurService.postCollaboratorsList(collab, this.getNpm, numeroContrat, versionContrat)) {
        toast.createAndPushToast(
          this.$t('collaborateurs.toast.ajout', { firstName: payload.prenom }).toString(),
          '',
          true,
          ERRORTYPE.DEFAULT
        );
        await collaborateurs.initCollaborateurs({ npm: this.getNpm, demo: this.getDemo });
      }
    }
  }
  private getCollaborateurByNumero = (nppCollaborateur: string) => {
    return collaborateurs?.getCollaborateurs?.find(c => c.npp === nppCollaborateur);
  };

  private async radierCollaborateur(data: any) {
    await this.initCollaborateurs();
    this.panelRadiationState = false;
  }

  private async updateCollaborateur(nppCollaborateur: string) {
    const collabs = collaborateurs.getCollaborateurs;
    if (collabs) {
      const collab = collabs.filter(c => c.npp === nppCollaborateur)[0];
      if (!collab.npp) return;
      await collaborateurs.editCollaborateur(collab.npp);
      const ref = this.refs.addCollaborateur;
      ref.updateCollaborateur();
    }
  }

  private async validUpdateCollaborateur(payload: Collaborateur) {
    const numeroContrat = this.getContractInfo?.numeroContrat;
    const versionContrat = this.getContractInfo?.versionContrat;
    if (this.getNpm && numeroContrat && versionContrat != null) {
      if (await CollaborateurService.updateCollaborateur(payload, this.getNpm, numeroContrat, versionContrat)) {
        await this.initCollaborateurs();
        toast.createAndPushToast(
          this.$t('collaborateurs.toast.edition', { firstName: payload.prenom }).toString(),
          '',
          true,
          ERRORTYPE.DEFAULT
        );
      }
    }
  }

  get stats() {
    return collaborateurs.getStats;
  }

  get getContractInfo() {
    return contextEmployeur.getEmployeur?.contrat;
  }

  get getNpm() {
    return contextEmployeur.getEmployeur?.numeroPersonneMorale;
  }

  get getCollaborateurs() {
    return collaborateurs.getCollaborateurs;
  }

  get getDemo() {
    return demo.demoState;
  }
}
